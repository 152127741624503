<template>
    <auth-layout>
        <div class="ce-login-card">
            <h3 class="ce-login-card-title">Enter Verification Code</h3>
            <form @submit.prevent="">
                <div class="ce-login-card-item-wrap">
                    <p>We sent
                        <template v-if="code_option === 'email'">an email to {{ user.email }}</template>
                        <template v-if="code_option === 'phone'">a message to {{ user.phone }}</template>
                        with your verification code. Enter it below to continue.
                    </p>
                    <div class="form-group">
                        <input type="number" name="code" placeholder="4-digit code" v-model="code" class="form-control">
                    </div>
                <label v-if="countDown!==0">Please wait for {{this.countDown}} seconds to resend verification code.</label>
                </div>

                <div class="ce-login-btn-wrap">
                    <a :style="{ width:100}" href="/" role="button" class="btn ce-btn-cancel-submit">Cancel</a>&nbsp;
                    <button class="btn ce-btn-cancel-submit" :disabled="countDown!==0" v-on:click="authenticate(code_option)">Resend</button>&nbsp;
                    <button class="btn ce-btn-login-submit" v-on:click="authenticateCode(code)">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </auth-layout>
</template>

<script>
    import {mapActions, mapState} from 'vuex';
    import {
      ACTION_AUTHENTICATE_CODE
    } from '@/store/modules/User';
    import AuthLayout from '@/layouts/AuthLayout';
    import Api from "@/includes/Api";

    export default {
        data() {
            return {
              code: '',
              code_option: false,
              countDown: 60
            }
        },
        components: {
            'auth-layout': AuthLayout
        },
        computed: mapState('User', ['user']),
        methods: {
            ...mapActions('User', {
                authenticateCode: ACTION_AUTHENTICATE_CODE
            }),
            authenticate(code_option){
              this.countDown=60;
              this.countDownTimer();
              Api.post('/authenticate', {data: code_option,user_id: this.user.id}).then((response) => {
                if (response.data.status === 'success') {

                  Api.alert('success', response.data.message);

                }
              });
            },
          countDownTimer() {
            if( this.countDown > 0 ) {
              setTimeout(() => {
                this.countDown--;
                this.countDownTimer();
              },1000 );
            }
          }
        },
        created() {
            this.code_option = this.$route.params.type;
        },mounted(){
          this.countDownTimer();
      }
    }
</script>
